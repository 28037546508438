export const aboutColumns = [
  {
    number: 1,
    title: `Attention to detail`,
    desc: `Vape meh cray vexillologist hexagon, PBR&B bespoke sartorial cardigan listicle umami snackwave woke jean shorts kogi.`,
  },
  {
    number: 2,
    title: `Highest quality`,
    desc: `Gastropub normcore bitters hashtag you probably haven't heard of them. Fam mixtape echo park`,
  },
  {
    number: 3,
    title: `Reasonable prices`,
    desc: `Vape meh cray vexillologist hexagon, PBR&B bespoke sartorial cardigan listicle umami snackwave woke jean shorts kogi.`,
  },
];
